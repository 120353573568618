import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import DownloadHandler from '../../DownloadHandler/DownloadHandler'
import { isAndroid, isIOS } from '../../../formatForBigQuery'
import './InstallHandler.scss'

interface Props {
  product?: boolean
  pokerLink?: Record<'android' | 'ios' | 'web', string>
  rummyLink?: Record<'android' | 'ios' | 'web', string>
  lang?: string
  pokerBtnText?: string
}

const InstallHandler: React.FC<Props> = ({
  pokerLink,
  rummyLink,
  lang,
  product = false,
  pokerBtnText,
}) => {
  const [platform, setPlatform] = useState('web') // default set to web
  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  const getOneLink = (
    type: string,
    oneLink: Record<'android' | 'ios' | 'web', string> | undefined
  ) => {
    switch (type) {
      case 'android':
        return oneLink?.android
      case 'ios':
        return oneLink?.ios
      default:
        return oneLink?.web
    }
  }

  const loginText = lang === 'hindi' ? 'लॉगिन / साइनअप' : 'LOGIN / SIGNUP'
  const playInstantText = lang === 'hindi' ? 'अभी खेलें' : 'Play Instantly'
  const downloadText = lang === 'hindi' ? 'डाउनलोड करें' : 'Download'
  const iosAndroidText = platform === 'ios' ? playInstantText : downloadText

  return (
    <div className={product ? 'bannerAppInstall product' : 'bannerAppInstall'}>
      {pokerLink && (
        <>
          <div className="installRow">
            <div className="installIcon">
              <StaticImage
                src="../../../images/thumbnails/Verticle_TexasHoldem.png"
                alt="Poker"
                width={40}
              />
            </div>
            <div className="installInfo">
              {lang === 'hindi' ? 'पोकर' : 'Poker'}
            </div>
            <div className="installButton">
              <DownloadHandler
                btnText={
                  pokerBtnText ||
                  (platform === 'web' ? loginText : iosAndroidText)
                }
                game="poker"
                platform={platform}
                apkLink={getOneLink(platform, pokerLink)}
              />
            </div>
          </div>
        </>
      )}
      {rummyLink && (
        <div className="installRow">
          <div className="installIcon">
            <StaticImage
              src="../../../images/thumbnails/Rummy-Thumbnails.png"
              alt="paytm"
              width={40}
            />
          </div>
          <div className="installInfo">
            {lang === 'hindi' ? 'रमी' : 'Rummy'}
          </div>
          <div className="installButton">
            <DownloadHandler
              btnText={platform === 'web' ? loginText : iosAndroidText}
              game="rummy"
              platform={platform}
              apkLink={getOneLink(platform, rummyLink)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default InstallHandler
