import { IPokerTermsData } from './terms'

export const pokerTerms: IPokerTermsData[] = [
  {
    char: 'A',
    data: [
      {
        heading: 'Ace- High',
        description:
          'A hand where the highest card is an ace and there are no straights, flushes or paired cards.',
      },
      {
        heading: 'Ace- Full',
        description:
          'This hand is a full house that consists of three aces with any other pair.',
      },
      {
        heading: 'Ace- Up',
        description:
          'This is when you hold a pair of aces that is combined with another pair to make two pair.',
      },
      {
        heading: 'Ace- On',
        description:
          'This is when you hold a pair of aces that is combined with another pair to make two pair.',
      },
      {
        heading: 'Ace- In',
        description:
          'A hand where the highest card is an ace and there are no straights, flushes or paired cards.',
      },
      {
        heading: 'Aces',
        description:
          "This is another forced bet that seeds a pot preflop in addition to the blinds. All players have to contribute an ante. Antes are commonly used in hold'em tournaments and they increase in value as the tournament progresses.",
      },
    ],
  },
  {
    char: 'B',
    data: [
      {
        heading: 'Bad Beat',
        description:
          'A terrible beat occurs when the hand with the best statistical probability of winning the pot is defeated by a weaker hand that improves due to the community cards dealt on the board.',
      },
      {
        heading: 'Bankroll Management',
        description:
          'Playing in games with the appropriate limit or buy-in size for your bankroll; as your bankroll grows, you can advance to higher games; as it shrinks, you can go back down.',
      },
      {
        heading: 'Big Blind',
        description:
          'This is the largest forced bet before the flop. It is often double the size of a tiny blind.',
      },
      {
        heading: 'Blank',
        description:
          'This community card, often known as a brick, does not strengthen your hand.',
      },
      {
        heading: 'Bluff',
        description:
          'A bet placed with a poor hand to make it appear stronger than it is to your opponents. You hope that this conduct will cause your opponents to fold.',
      },
      {
        heading: 'Bounty',
        description:
          'In multi-table tournaments (MTTs), we place a bounty on specific players, resulting in a payout for whoever knocks them out of the game.',
      },
      {
        heading: 'Broadway Straight',
        description: 'This is an ace-high straight (say, A-K-Q-J-10).',
      },
      {
        heading: 'Bubble',
        description:
          'The money bubble bursts when the final player in a tournament is eliminated before the prize money begins to be given. If the top 40 players in a competition get rewarded, the unlucky person who is eliminated in the 41st place has popped the bubble.',
      },
      {
        heading: 'Button',
        description:
          'The button denotes the player that is starting from the dealer position. The little disk revolves clockwise around the table, determining where players will act on each hand.',
      },
      {
        heading: 'Buy-in',
        description:
          'The amount of money you start with in a cash game or the entry fee for a tournament.',
      },
    ],
  },
  {
    char: 'C',
    data: [
      {
        heading: 'Call',
        description:
          'If you just equal the wager that a player has placed in front of you, you are calling that bet.',
      },
      {
        heading: 'Calling Station',
        description:
          'A weak-passive player who frequently calls but rarely raises or folds. This is the type of player you want to have in your game.',
      },
      {
        heading: 'Community Cards',
        description:
          'In games such as Hold\'em and Omaha, cards are dealt face up in the center of the poker table and split among players. These are sometimes known as board cards or "the board".',
      },
      {
        heading: 'Crack',
        description:
          'To beat a hand, usually a large hand. This is most commonly used to describe pocket aces: "Third time tonight, I\'ve had pocket aces busted."',
      },
      {
        heading: 'Cash Game',
        description:
          'In a cash game, your chip stack symbolises the actual monetary value. Blinds and antes are predetermined for the table and do not increase as in a tournament. You can join or exit a cash game whenever you wish.',
      },
      {
        heading: 'Check',
        description:
          'If you opt not to bet or raise when it is your turn to act, and there are no bets or raises in front of you, you have checked.',
      },
      {
        heading: 'Check-Raise',
        description:
          'Check-raising occurs when you check and then wait for other players to bet behind you in order to raise them when it is your turn to act again.',
      },
    ],
  },
  {
    char: 'D',
    data: [
      {
        heading: 'Dealer',
        description:
          'During a poker hand, a player sits with the button in front of them. They are also the last to act on every betting round, providing the dealer with a competitive advantage on every hand.',
      },
      {
        heading: 'Dominated Hand',
        description:
          'A hand that almost always loses to a superior hand that is commonly played. For example, K3 is "ruled" by KQ. With the exception of unusual flops (such as 3-3-X and K-3-X), it will always lose to KQ.',
      },
      {
        heading: 'Draw a Hand',
        description:
          'Attempting to create a hand that, even if successful, will not win the pot. If you\'re drawing for a flush and your opponent already has a full house, you\'re "drawing dead." Naturally, this is a dreadful situation to be in.',
      },
    ],
  },
  {
    char: 'E',
    data: [
      {
        heading: 'Early Positions',
        description:
          'Players in early positions must act before the bulk of the table during a betting round. The player under the gun is the first to act preflop, which puts him in an early position and at a disadvantage. This is the amount of money you have deposited into your Ultimate Poker account.',
      },
      {
        heading: 'Entry Fee',
        description:
          'A minor fee is imposed by a brick-and-mortar casino or internet poker facility for organizing a poker event.',
      },
      {
        heading: 'Extra Blind',
        description:
          'A blind player is placed by a player who is new to the game, returning to the game, or changing positions at the table. See also: "blind" and "post."',
      },
    ],
  },
  {
    char: 'F',
    data: [
      {
        heading: 'Family Pot',
        description:
          'It is a pot in which all (or nearly all) players call before the flop.',
      },
      {
        heading: 'Fifth Street',
        description:
          "This is the fifth card dealt to the board in a hold'em poker hand. It's also named the river. In a hold'em poker hand, the final round of betting occurs after the last face-community card.",
      },
      {
        heading: 'Fish',
        description:
          'A losing poker player with less experience or skill than his opponents, better players make aggressive bets against fish in order to earn money.',
      },
      {
        heading: 'Flop',
        description:
          'The flip refers to the first three cards dealt face up on the board. This is followed by another round of betting before the next communal card is revealed.',
      },
      {
        heading: 'Flush',
        description:
          'Flush occurs when a poker hand consists of five cards of the same suit (e.g., all spades). The best flush you can have is an ace-high flush.',
      },
      {
        heading: 'Flush Draw',
        description:
          'If you have four cards of the same suit and remain in the hand in the hopes of receiving a fifth card of that suit, you are on a flush draw. In this position, some players may attempt a semi-bluff.',
      },
      {
        heading: 'Fold',
        description:
          'When you fold, you stop participating in the hand and give up your hole cards.',
      },
      {
        heading: 'Four of a Kind',
        description: 'Any time you have four cards with the same value.',
      },
      {
        heading: 'Fourth Street',
        description:
          'This is also known as a turn. Fourth Street is the fourth face-up card dealt on the board, and all players can use it to construct the finest five-card poker hand. Another round of betting follows it.',
      },
      {
        heading: 'Freeroll',
        description:
          'Freeroll tournaments are free to enter and often give prizes.',
      },
      {
        heading: 'Full House',
        description:
          'A full house is formed when you have a five-card poker hand consisting of three cards of the same value plus any other pair. This is also known as a full boat.',
      },
    ],
  },
  {
    char: 'G',
    data: [],
  },
  {
    char: 'H',
    data: [
      {
        heading: 'Heads-Up',
        description:
          'This occurs when there are only two players left in a hand or poker tournament. The winner will take home the pot or tournament prize.',
      },
      {
        heading: 'Hole Cards',
        description:
          'Each player receives two hole cards at the beginning of each hand. These are the only cards that can be used in conjunction with the five community cards to form the best five-card poker hand.',
      },
    ],
  },
  {
    char: 'I',
    data: [
      {
        heading: 'In the Money',
        description:
          'You finish "in the money" in a poker tournament when the money bubble bursts, and every remaining player earns prize money when they bust out.',
      },
    ],
  },
  {
    char: 'K',
    data: [
      {
        heading: 'Kicker',
        description:
          "An unpaired card is used to evaluate the better of two almost identical hands. For example, assume you have AK, and your opponent has AQ. If the flop contains an ace, you have a pair of aces and a king kicker. Kickers can be quite essential in hold'em.",
      },
    ],
  },
  {
    char: 'L',
    data: [
      {
        heading: 'Late Positions',
        description:
          'This is the position in a betting round where a player acts after the majority of the other players have done so. This is the location to the right of the button.',
      },
      {
        heading: 'Limp',
        description:
          'If you simply call the big blind to enter a pot preflop, you have limped in, most likely intending to enhance your hand after the first three cards are dealt on the flop.',
      },
    ],
  },
  {
    char: 'M',
    data: [
      {
        heading: 'Micro Limits',
        description:
          'These are low-buy-in tournaments or cash game levels that beginners or players with minimal bankrolls can use to gain experience.',
      },
      {
        heading: 'Middle Position',
        description:
          "If you are the fifth, sixth, or seventh player to act at a full poker table during a game of hold'em poker, you are in the middle position. You get to see a few people act before you, but other players can also see what you do.",
      },
      {
        heading: 'Minimum Buy-In',
        description:
          "To take a seat at a cash game table, a minimum buy-in is required. These are frequently multiples of the large blind. For example, a $2-$4 limit hold'em game could have a $40 minimum buy-in.",
      },
      {
        heading: 'MTT',
        description:
          'A multi-table tournament (MTT) is one with more than ten players. The prize pool grows as more players participate, making MTTs a popular way for players to win a lot of money with a little buy-in.',
      },
      {
        heading: 'Muck',
        description:
          'A pile of folded and burned cards is in front of the dealer. "His hand hit the muck, so the dealer ruled it folded, even though the guy wanted to get his cards back." Also used as a verb. "He didn\'t have any outs, so he mucked his hand."',
      },
    ],
  },
  {
    char: 'N',
    data: [],
  },
  {
    char: 'O',
    data: [
      {
        heading: 'Odds',
        description:
          'The likelihood of forming a hand vs the probability of not making a hand is calculated by dividing the number of cards left in the deck that will improve your hand by the number of cards that will not.',
      },
      {
        heading: 'Offsuit',
        description:
          'When you have two hole cards of different suits, K-clubs and Q-clubs, you have stronger hands than K-spades or Q-clubs.',
      },
      {
        heading: 'On Tilt',
        description:
          "This occurs when a player's emotions take over following a run of bad luck. A gamer on tilt may play too many hands or verbally vent their anger to other players.",
      },
      {
        heading: 'Open-ended Straight Draw',
        description:
          'If a player holds four consecutive cards in a sequence, such as J-10-9-8, adding a card on each results in a straight. In this case, the player hopes to draw a queen or a seven to complete a straight.',
      },
      {
        heading: 'Outs',
        description:
          "Use the remaining cards in the deck to improve your hand. For example, the hand given in the open-ended straight draw description above had eight outs, four queens, or four sevens, all of which may improve the player's hand to a straight.",
      },
    ],
  },
  {
    char: 'P',
    data: [
      {
        heading: 'Pair',
        description:
          'A pair is a hand that contains two cards of the same value, such as two kings or two sevens.',
      },
      {
        heading: 'Pocket Pair',
        description:
          'When you get a pair of cards of the same value in the hole preflop in a poker hand, such as pocket fours or pocket jacks.',
      },
      {
        heading: 'Pocket Rockets',
        description:
          'When you are handed a pair of aces in the hole before the flop in a poker hand, this is the ideal starting hand in poker.',
      },
      {
        heading: 'Position',
        description:
          "A player's position on the table is determined by where they sit in reference to the dealer. If they are situated directly to the dealer's left, they are in an early position. If they sit directly to the player's right, they are in a late position.",
      },
      {
        heading: 'Pot',
        description:
          'When players show down their cards, the chips or money they have bet on a hand are up for grabs, and the player with the best five-card hand wins.',
      },
      {
        heading: 'Pot Odds',
        description:
          'This is when you calculate the chances of your hand winning and compare them to the size of the pot and the current bet you are facing. This is a mechanism to determine whether a call is mathematically valid.',
      },
      {
        heading: 'Pre-Flop',
        description:
          'Preflop betting occurs prior to the first three cards being dealt. This is the round of betting in which the small blind, big blind, and ants seed the pot to generate interest.',
      },
      {
        heading: 'Prize Pool',
        description:
          'All of the entrance money given by participants in a tournament goes towards the overall prize money offered to players who make a deep run in the event; the prize pool is divided and is typically awarded to the top 10% of finishers in the tournament.',
      },
    ],
  },
  {
    char: 'Q',
    data: [
      {
        heading: 'Quads',
        description:
          'This is also known as four-of-a-kind and occurs when a player has four cards with the same value in their poker hand (for example, four kings).',
      },
    ],
  },
  {
    char: 'R',
    data: [
      {
        heading: 'Rainbow',
        description:
          'A flop with three different suits delivered faces up (K-clubs, J-hearts, and 10-spades, for example), making it improbable that any player will complete a flush on the hand.',
      },
      {
        heading: 'Rake',
        description:
          'The house takes a tiny amount of money from a pot as a fee for hosting a cash game.',
      },
      {
        heading: 'Rank',
        description:
          'The value of a card or hand is used to determine whether poker hands win and lose in a specific order. An ace is the highest card rank, whereas a royal flush is the highest hand rank.',
      },
      {
        heading: 'Re-Buy',
        description:
          'If a player loses all of their chips before the start of a rebuy tournament, they can re-enter for a limited time. Only rebuy tournaments provide this option, and their prize pools are greater due to repeated entries.',
      },
      {
        heading: 'Re-Raise',
        description:
          'A re-raise, also known as a three-bet, occurs when a player chooses to raise again after having placed a stake and raised previously.',
      },
      {
        heading: 'River',
        description:
          'The last card dealt face-up on the table during a poker hand. It is also known as Fifth Street because it is the fifth and last community card dealt before the final round of betting.',
      },
      {
        heading: 'Royal Flush',
        description:
          'The greatest ranking hand in poker is formed when a player has an ace-high straight with all of the cards being of the same suit; bet as much as possible to extract money from our opponents.',
      },
    ],
  },
  {
    char: 'S',
    data: [
      {
        heading: 'Satellite',
        description:
          'A tournament with a low buy-in is often played at a single table, with the winner receiving entrance into a larger tournament as the prize.',
      },
      {
        heading: 'Shark',
        description:
          'A successful poker player who frequently makes aggressive moves and takes money from the less experienced players at their table.',
      },
      {
        heading: 'Short Stacked',
        description:
          'If you are one of the lower stacks at a cash game table in comparison to your opponents, or if you have ten large blinds or less remaining in a tournament.',
      },
      {
        heading: 'Showdown',
        description:
          'After the river card, the remaining players in hand must flip over their cards to decide the best hand. This is known as a showdown.',
      },
      {
        heading: 'Side Pot',
        description:
          'If one player goes all in and there are still other players with more chips to play with in the pot, they will compete for a side pot that is more than the value of the main pot. The all-in player is ineligible to win the side pot.',
      },
      {
        heading: 'Sit N Go',
        description:
          'This is a single-table event with ten players, and the last player to survive wins the tournament. Satellites are often sit-and-go competitions.',
      },
      {
        heading: 'Small Blinds',
        description:
          'During the first round of betting, the player seated directly to the left of the dealer placed a forced bet into the pot. This is often half the value of the large blind.',
      },
      {
        heading: 'Starting Stack',
        description:
          'This is the number of chips you start with in a tournament or sit-and-go. In typical competitions, all players start the same way.',
      },
      {
        heading: 'Straight',
        description:
          'refers to a hand of five consecutive cards in sequential order (10-9-8-7-6).',
      },
      {
        heading: 'Straight Flush',
        description:
          'The hand 9♠8♠7♠6♠5♠ is a straight flush, defined as five consecutive cards of the same suit.',
      },
      {
        heading: 'Suited Connector',
        description:
          'The hole cards 9♠8♠ are suited connectors, meaning they can join any two hole cards of the same suit in that order.',
      },
    ],
  },
  {
    char: 'T',
    data: [
      {
        heading: 'Three-Bet',
        description:
          'Also known as a re-raise, this occurs when a player meets a bet and raises in front of them before deciding to raise again.',
      },
      {
        heading: 'The Board',
        description:
          'The board is a display of a combination of the flop, turn, and river cards.',
      },
      {
        heading: 'Turn',
        description:
          'During a poker hand, the fourth card is dealt face up to the table. Another round of betting follows Fourth Street.',
      },
    ],
  },
  {
    char: 'U',
    data: [
      {
        heading: 'Under the Gun',
        description:
          'This is the person who sits immediately to the left of the large blind during the first round of betting. They are the first participants to participate in a poker hand.',
      },
    ],
  },
  {
    char: 'V',
    data: [
      {
        heading: 'Variance',
        description:
          "This graph depicts a poker player's statistical distribution of results over time. Variance affects short-term swings in a player's bankroll when they have a strong or bad run of cards.",
      },
    ],
  },
  {
    char: 'W',
    data: [
      {
        heading: 'Wheel',
        description: 'This is a 5-high straight (5-4-3-2-A, for example).',
      },
    ],
  },
  {
    char: 'X',
    data: [],
  },
  {
    char: 'Y',
    data: [],
  },
  {
    char: 'Z',
    data: [],
  },
]
