import React, { useState } from 'react'
import { Portal } from 'react-portal'
import DownloadInstruction from '../DownloadInstruction/DownloadInstruction'
import { GENERIC_APK } from '../download-links'
import LinkButton from '../LinkButton/LinkButton'
import './DownloadHandler.scss'

declare global {
  interface Window {
    chrome: unknown
    opr: unknown
  }
}

interface Props {
  apkLink?: string
  btnColor?: string
  btnText?: string | React.ReactNode
  game?: string
  platform?: string
  btnTextStyle?: React.CSSProperties
}

const DownloadHandler: React.FC<Props> = ({
  apkLink = GENERIC_APK,
  btnColor,
  btnText = 'Download App Now',
  game,
  platform,
  btnTextStyle,
}) => {
  const [showInstruction, setShowInstruction] = useState(false)

  return (
    <>
      <LinkButton
        to={apkLink}
        className={`download-btn ${apkLink} ${btnColor || ''}`}
        style={btnTextStyle}
        onClick={() => {
          try {
            window?.fbq?.('track', 'Lead')

            window?.trackGLDownloadEvent?.()
          } catch (er) {
            console.error(er)
          }
          setShowInstruction(platform === 'android' && game === 'poker')
        }}
      >
        {btnText}
      </LinkButton>
      {showInstruction && (
        <Portal>
          <DownloadInstruction
            game={game}
            close={() => {
              setShowInstruction(false)
            }}
          />
        </Portal>
      )}
    </>
  )
}

export default DownloadHandler
