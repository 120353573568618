import React, { useEffect, useState } from 'react'
import { POKER, POKER_TERMS } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import { POKER_TERMS as POKER_DOWNLOAD_LINK } from '../../components/download-links'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import { Container } from 'react-bootstrap'
import './terms.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { pokerTerms } from './termsData'
import SendSms from '../../components/Index/MainScreen/Desktop/SendSms'
import InstallHandler from '../../components/Index/MainScreen/InstallHandler'
import InfoCard from '../../components/Games/CategoryAndGamesInfo/InfoCard'
import PokerInfo from '../../components/Games/CategoryAndGamesInfo/PokerInfo'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_VARIANTS_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: POKER_VARIANTS_BREADCRUMB_TITLE,
    url: POKER,
  },
  {
    title: 'Poker Terms',
    url: POKER_TERMS,
  },
]

export interface IPokerTermsData {
  char: string
  data: IData[]
}

interface IData {
  heading: string
  description: string
}

const PokerTerms: React.FC = () => {
  const [selectedTerms, setSelectedTerms] = useState('A')
  const [pokerTermsData, setPokerTermsData] = useState<IData[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (!searchTerm) {
      const selectedTerm = pokerTerms.find(term => term.char === selectedTerms)
      setPokerTermsData(selectedTerm?.data as IData[])
    }
  }, [selectedTerms])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!searchTerm) {
      return
    }
    const onlyData = pokerTerms.map(term => term.data).flat()
    const searchResult = onlyData.filter(term =>
      term.heading.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setSelectedTerms('')
    setPokerTermsData([...searchResult])
  }

  const selectedTerm = pokerTermsData.map(el => {
    return (
      <>
        <h2>{el.heading}</h2>
        <p>{el.description}</p>
      </>
    )
  })
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={POKER_DOWNLOAD_LINK}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
    >
      <SEO
        title="Poker Terms: The ones that a Pro Poker player must know | Mega"
        description="Get to know the basic poker terms. Read all the terms which would definitely help you understand poker in a better way. Read the article now."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />

      <div className="main-container">
        <div className="top-banner">
          <div className="text-container">
            <h1>Poker Dictionary</h1>
            <p>Find everything you are looking for in one place </p>
          </div>
          <div className="image-container">
            <div className="d-block d-md-none">
              <StaticImage
                loading="eager"
                src="../../images/dictionary 1.png"
                alt="dictionary"
                layout="fixed"
                width={324}
              />
            </div>
            <div className="d-none d-md-block">
              <StaticImage
                loading="eager"
                src="../../images/dictionary 1.png"
                alt="dictionary"
                layout="fixed"
                width={500}
              />
            </div>
          </div>
        </div>
        <div className="install-handler">
          <p className="d-none d-md-block">Get Welcome Bonus ₹17,000*</p>
          <div className="d-none d-md-block">
            <div className="desktop-handler">
              <div className="logo-container">
                <StaticImage
                  src="../../images/poker-terms.png"
                  alt="Poker"
                  width={45}
                />
              </div>
              <SendSms
                smsLink={pokerOneLinksPokerPage}
                downloadBtnText={'Download Poker App'}
                game="poker"
                btnTextPokerEnglish="Play Instantly"
                btnTextStyle={{
                  color: '#000',
                  fontSize: '1rem',
                  fontFamily: '"Gotham Rounded", sans-serif',
                  textTransform: 'uppercase',
                  fontWeight: 'bolder',
                }}
              />
            </div>
          </div>
          <div className="d-block d-md-none">
            <InstallHandler product pokerLink={pokerOneLinksPokerPage} />
          </div>
        </div>
        <Container>
          <div className="container-poker">
            <h1 className="dictinory-header">Poker Dictionary online</h1>
            <div className="char-container">
              {pokerTerms.map(term => (
                <button
                  disabled={term.data.length === 0}
                  className={
                    selectedTerms === term.char
                      ? 'selected'
                      : term.data.length === 0
                      ? 'disabled'
                      : ''
                  }
                  key={term.char}
                  onClick={() => setSelectedTerms(term.char)}
                >
                  {term.char}
                </button>
              ))}
            </div>
            <form className="search-container" onSubmit={handleSubmit}>
              <input
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value)
                  if (e.target.value === '') {
                    setSelectedTerms('A')
                  }
                }}
                className="text-search"
                type="text"
                placeholder="Search anything..."
              />
              <button className="search-icon" type="submit">
                <StaticImage
                  loading="eager"
                  src="../../images/Search.svg"
                  alt="search"
                  className="search-icon-image"
                  layout="fixed"
                  width={26}
                  height={26}
                />
              </button>
            </form>
          </div>
          <InfoCard>
            <PokerInfo englishContentFromPerent={selectedTerm} />
          </InfoCard>
        </Container>
      </div>
    </Layout>
  )
}

export default PokerTerms
