import React, { useEffect, useState } from 'react'
import './SendSms.scss'
import { isAndroid, isIOS } from '../../../../formatForBigQuery'
import {
  pokerOneLinksOtherPage,
  rummyOneLinksOtherPage,
} from '../../../one-links'
import DownloadHandler from '../../../DownloadHandler/DownloadHandler'

interface Props {
  downloadBtnText?: string | React.ReactNode
  game?: string
  lang?: string
  smsLink?: any
  btnTextPokerEnglish?: string | React.ReactNode
  btnTextRummyEnglish?: string | React.ReactNode
  btnTextStyle?: React.CSSProperties
}

const SendSms: React.FC<Props> = ({
  downloadBtnText,
  smsLink,
  lang,
  game = 'poker',
  btnTextPokerEnglish,
  btnTextRummyEnglish,
  btnTextStyle,
}) => {
  // checking which app (or web) link we need to send to the user
  const [platform, setPlatform] = useState('web') // default set to web

  const [oneLinkSms, setOneLinkSms] = useState('')

  const smsDefaultLink =
    game === 'rummy' ? rummyOneLinksOtherPage : pokerOneLinksOtherPage

  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  useEffect(() => {
    const defaultPlatform = platform === 'ios' ? 'ios' : 'android'

    setOneLinkSms(smsLink?.[platform] || smsDefaultLink?.[defaultPlatform])
  }, [smsDefaultLink, smsLink, platform])

  const downloadText = lang === 'hindi' ? 'डाउनलोड करें' : 'Download App'

  const buttonTextPoker =
    lang === 'hindi' ? <>अभी खेलें</> : btnTextPokerEnglish || <>Play Poker</>

  const buttonTextRummy =
    lang === 'hindi' ? <>अभी खेलें</> : btnTextRummyEnglish || <>Play Rummy</>

  return (
    <div className="action-button-wrap">
      {platform === 'ios' || platform === 'android' ? (
        <DownloadHandler
          apkLink={oneLinkSms}
          btnText={downloadBtnText || downloadText}
          game={game}
          platform={platform}
          btnTextStyle={btnTextStyle}
        />
      ) : (
        <a
          href={oneLinkSms}
          className={`${platform} web-play-link`}
          target="_blank"
          rel="nofollow"
          data-link={oneLinkSms}
          style={btnTextStyle}
        >
          <span>{game === 'poker' ? buttonTextPoker : buttonTextRummy}</span>
        </a>
      )}
    </div>
  )
}

export default SendSms
