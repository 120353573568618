import { Link } from 'gatsby'
import React from 'react'

import './LinkButton.scss'

interface Props {
  color?: string
  background?: string
  width?: string
  className?: string
  to?: string
  id?: string
  children: React.ReactNode
  target?: string
  onClick?(): void
  style?: React.CSSProperties
}

const LinkButton: React.FC<Props> = props => {
  const {
    color,
    background,
    width,
    className,
    to,
    id,
    target,
    children,
    onClick,
    style,
  } = props

  const linkCode =
    to && /^\/(?!\/)/.test(to) ? (
      <Link
        to={to}
        id={id}
        style={{ color, ...style }}
        target={target}
        rel="nofollow"
        onClick={onClick}
      >
        {children}
      </Link>
    ) : (
      <a
        href={to}
        id={id}
        style={{ color, ...style }}
        target={target}
        rel="nofollow"
        onClick={onClick}
      >
        {children}
      </a>
    )

  return (
    <div className={`link-button ${className}`} style={{ background, width }}>
      {to ? (
        linkCode
      ) : (
        <button
          id={id}
          type="button"
          style={{ color, ...style }}
          onClick={onClick}
        >
          {children}
        </button>
      )}
    </div>
  )
}

LinkButton.defaultProps = {
  color: 'black',
  background: 'white',
  width: '200px',
  className: '',
}

export default LinkButton
