import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import './DownloadInstruction.scss'

interface Props {
  close(): void
  game?: string
}

const DownloadInstruction: React.FC<Props> = ({ close, game }) => {
  const data = useStaticQuery(graphql`
    {
      rummy: file(relativePath: { eq: "how_to_install_rummy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      poker: file(relativePath: { eq: "how_to_install_poker.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <div className="instruction_pop">
      <div className="instruction_area">
        {game === 'rummy' ? (
          <GatsbyImage
            image={data.rummy.childImageSharp.gatsbyImageData}
            alt="Rummy"
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : (
          <GatsbyImage
            image={data.poker.childImageSharp.gatsbyImageData}
            alt="Poker"
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
      </div>
      <div className="close-div">
        <button
          className="image-button"
          type="button"
          onClick={(e): void => {
            e.stopPropagation()
            e.preventDefault()
            close()
          }}
        >
          <StaticImage
            loading="eager"
            src="../../images/close-white.png"
            alt="close"
            className="close"
            width={72}
          />
        </button>
      </div>
    </div>
  )
}

export default DownloadInstruction
